import React from 'react';
import { Layout, Form, Input, Button, Typography, Row, Col, Space, message, Select } from 'antd';
import { MailOutlined, PhoneOutlined, UserOutlined, EnvironmentOutlined, CalendarOutlined, TeamOutlined, DollarOutlined, MessageOutlined } from '@ant-design/icons';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import WebFont from 'webfontloader';
import emailjs from '@emailjs/browser';

WebFont.load({
  google: {
    families: ['Balerno']
  }
});

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const ContactPage = () => {
    const onFinish = async (values) => {
        try {
            // Comment out backend API request for now
            /*
            const [backendResponse, emailResponse] = await Promise.all([
                fetch('/api/contact', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values)
                }),
            */

            // Only send to EmailJS
            const emailResponse = await emailjs.send(
                'service_07c3c5j',
                'template_rf3g3dj',
                {
                    nameAndSurname: values.nameAndSurname,
                    emailAddress: values.emailAddress,
                    contactNumber: values.contactNumber,
                    locationVenue: values.locationVenue,
                    date: values.date,
                    numberOfGuests: values.numberOfGuests,
                    estimatedBudget: values.estimatedBudget,
                    subject: values.subject,
                    message: values.message,
                },
                'kbsqKLRUlWKrA_Drw'
            );

            // Check only EmailJS response
            if (emailResponse.status === 200) {
                message.success('Message sent successfully!');
                form.resetFields();
            }

        } catch (error) {
            console.error('Error sending message:', error);
            message.error('Failed to send message. Please try again later.');
        }
    };

    const [form] = Form.useForm();

    return (
        <Layout style={{ minHeight: '100vh', background: '#FFFFFF' }}>
            <Navbar />
            <Content style={{ paddingTop: ['60px', '120px'], paddingBottom: ['200px', '400px'] }}>
                <div style={{ 
                    textAlign: 'center', 
                    marginBottom: ['32px', '64px'], 
                    backgroundColor: '#ffffff', 
                    padding: '40px', 
                    borderRadius: '16px', 
                    paddingTop: ['92px', '184px'],
                    paddingBottom: ['92px'],
                    width: '100%'
                }}>
                    <Title level={1} style={{ 
                        fontFamily: 'Balerno', 
                        fontSize: ['32px', '48px'],
                        color: '#1a1a1a',
                        marginBottom: '24px'
                    }}>
                        Get in Touch
                    </Title>
                </div>

                <Row justify="center">
                    <Col xs={22} sm={20} md={18} lg={16} xl={14}>
                        <Row gutter={[48, 48]} align="stretch">
                            <Col xs={24} lg={10}>
                                <div style={{ 
                                    background: '#f8f9fa',
                                    padding: '40px',
                                    borderRadius: '16px',
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    <Space direction="vertical" size={32} style={{ width: '100%' }}>
                                        <div>
                                            <Title level={3} style={{ 
                                                fontFamily: 'Balerno',
                                                marginBottom: '24px',
                                                color: '#1a1a1a'
                                            }}>
                                                COUNTRYWIDE SERVICE
                                            </Title>
                                            <Paragraph style={{ 
                                                fontFamily: 'Balerno',
                                                fontSize: '16px',
                                                color: '#666666',
                                                marginBottom: '16px'
                                            }}>
                                                Talk to us to plan your event today
                                            </Paragraph>
                                        </div>

                                        <div>
                                            <Title level={4} style={{ 
                                                fontFamily: 'Balerno',
                                                color: '#1a1a1a',
                                                marginBottom: '16px'
                                            }}>
                                                Contact
                                            </Title>
                                            <Space direction="vertical" size={16}>
                                                <Space size={16}>
                                                    <MailOutlined style={{ color: '#gold' }}/>
                                                    <span style={{ fontFamily: 'Balerno', color: '#666666' }}>
                                                        arisisharonn@gmail.com
                                                    </span>
                                                </Space>
                                                <Space size={16}>
                                                    <PhoneOutlined style={{ color: '#gold' }}/>
                                                    <span style={{ fontFamily: 'Balerno', color: '#666666' }}>
                                                        +254 (795) 825-320
                                                    </span>
                                                </Space>
                                            </Space>
                                        </div>

                                        <div>
                                            <Title level={4} style={{ 
                                                fontFamily: 'Balerno',
                                                color: '#1a1a1a',
                                                marginBottom: '16px'
                                            }}>
                                                Business Inquiries
                                            </Title>
                                            <Space direction="vertical" size={16}>
                                                
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                                    <MailOutlined style={{ color: '#gold' }}/>
                                                    <div style={{ 
                                                        fontFamily: 'Balerno',
                                                        color: '#666666',
                                                        display: 'flex',
                                                        flexDirection: 'column'
                                                    }}>
                                                        <span>Media & Brand:</span>
                                                        <span>
                                                            arisisharonn@gmail.com
                                                        </span>
                                                    </div>
                                                </div>
                                            </Space>
                                        </div>
                                    </Space>
                                </div>
                            </Col>

                            <Col xs={24} lg={14}>
                                <Form
                                    form={form}
                                    name="contact"
                                    onFinish={onFinish}
                                    layout="vertical"
                                    style={{ 
                                        background: '#FFFFFF',
                                        padding: ['20px', '40px'],
                                        borderRadius: '16px',
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                                        paddingBottom: ['32px', '64px']
                                    }}
                                >
                                    <Row gutter={[24, 0]}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="nameAndSurname"
                                                label={<span>Name and Surname </span>}
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input 
                                                    prefix={<UserOutlined style={{ color: '#gold' }}/>}
                                                    size="large"
                                                    placeholder="Name and Surname"
                                                    style={{ fontFamily: 'Balerno' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="emailAddress"
                                                label={<span>Email Address </span>}
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                    { type: 'email', message: 'Invalid email' }
                                                ]}
                                            >
                                                <Input 
                                                    prefix={<MailOutlined style={{ color: '#gold' }}/>}
                                                    size="large"
                                                    placeholder="Email Address"
                                                    style={{ fontFamily: 'Balerno' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[24, 0]}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="contactNumber"
                                                label={<span>Contact Number </span>}
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input 
                                                    prefix={<PhoneOutlined style={{ color: '#gold' }}/>}
                                                    size="large"
                                                    placeholder="Contact Number"
                                                    style={{ fontFamily: 'Balerno' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="locationVenue"
                                                label={<span>Location/Venue </span>}
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input 
                                                    prefix={<EnvironmentOutlined style={{ color: '#gold' }}/>}
                                                    size="large"
                                                    placeholder="Location/Venue"
                                                    style={{ fontFamily: 'Balerno' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[24, 0]}>
                                        <Col xs={24} md={8}>
                                            <Form.Item
                                                name="date"
                                                label={<span>Date </span>}
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input 
                                                    prefix={<CalendarOutlined style={{ color: '#gold' }}/>}
                                                    type="date"
                                                    size="large"
                                                    style={{ fontFamily: 'Balerno' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item
                                                name="numberOfGuests"
                                                label={<span>Number of Guests </span>}
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input 
                                                    prefix={<TeamOutlined style={{ color: '#gold' }}/>}
                                                    type="number"
                                                    size="large"
                                                    min={1}
                                                    placeholder="Number of Guests"
                                                    style={{ fontFamily: 'Balerno' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item
                                                name="estimatedBudget"
                                                label={<span>Budget (KSH) </span>}
                                                rules={[{ required: true, message: 'Required' }]}
                                            >
                                                <Input 
                                                    prefix={<DollarOutlined style={{ color: '#gold' }}/>}
                                                    type="number"
                                                    size="large"
                                                    min={0}
                                                    placeholder="Budget (KSH)"
                                                    style={{ fontFamily: 'Balerno' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        name="subject"
                                        label={<span>Event Type </span>}
                                        rules={[{ required: true, message: 'Required' }]}
                                    >
                                        <Select
                                            size="large"
                                            placeholder="Select Event Type"
                                            style={{ width: '100%', fontFamily: 'Balerno' }}
                                        >
                                            <Option value="Birthday Picnic">Birthday Picnic</Option>
                                            <Option value="Brand Collaboration">Brand Collaboration</Option>
                                            <Option value="Brand Setup">Brand Setup</Option>
                                            <Option value="Gifting">Gifting</Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="message"
                                        label={<span>Message </span>}
                                        rules={[{ required: true, message: 'Required' }]}
                                    >
                                        <TextArea
                                            prefix={<MessageOutlined style={{ color: '#gold' }}/>}
                                            rows={4}
                                            placeholder="Tell us about your vision"
                                            style={{ fontFamily: 'Balerno' }}
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button 
                                            htmlType="submit" 
                                            size="large" 
                                            block
                                            style={{
                                                height: '48px',
                                                padding: '0 32px',
                                                fontSize: '16px',
                                                color: 'gold',
                                                backgroundColor: 'transparent',
                                                border: '1px solid gold',
                                                transition: 'all 0.3s ease',
                                                fontFamily: 'Balerno, serif'
                                            }}
                                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 215, 0, 0.1)'}
                                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                                        >
                                            Send Message
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <section style={{ paddingTop: '120px', paddingBottom: '40px' }}> </section>
            </Content>
            <Footer />
        </Layout>
    );
};

export default ContactPage;
