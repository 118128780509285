import React, { useState, useEffect } from 'react';
import { Layout, Input, Row, Col, Button, Empty, Spin, Alert, Typography, Divider, Select, Modal, Form, DatePicker, InputNumber, message, Table, Tag, Space } from 'antd';
import { SearchOutlined, CalendarOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const generateSlug = (title) => {
  return title
    ?.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-') || '';
};

const isValidObjectId = (id) => /^[0-9a-fA-F]{24}$/.test(id);

function Sellers() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingEvent, setEditingEvent] = useState(null);
  const [form] = Form.useForm();
  const [dateRange, setDateRange] = useState([null, null]);
  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState('descend');

  const validLocations = [
    'Baringo', 'Bomet', 'Bungoma', 'Busia', 'Elgeyo Marakwet', 'Embu', 'Garissa',
    'Homa Bay', 'Isiolo', 'Kajiado', 'Kakamega', 'Kericho', 'Kiambu', 'Kilifi',
    'Kirinyaga', 'Kisii', 'Kisumu', 'Kitui', 'Kwale', 'Laikipia', 'Lamu',
    'Machakos', 'Makueni', 'Mandera', 'Marsabit', 'Meru', 'Migori', 'Mombasa',
    'Murang\'a', 'Nairobi', 'Nakuru', 'Nandi', 'Narok', 'Nyamira', 'Nyandarua',
    'Nyeri', 'Samburu', 'Siaya', 'Taita Taveta', 'Tana River', 'Tharaka Nithi',
    'Trans Nzoia', 'Turkana', 'Uasin Gishu', 'Vihiga', 'Wajir', 'West Pokot'
  ];

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/events`);
      if (!response.ok) throw new Error('Failed to fetch events');
      const eventsData = await response.json();
      setEvents(eventsData);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleSearchChange = (event) => setSearchTerm(event.target.value);

  const handleFilterChange = (value) => setFilter(value);

  const filteredEvents = events.filter((event) => {
    const matchesSearch = event?.title?.toLowerCase().includes(searchTerm.toLowerCase()) || false;
    const matchesFilter = filter === 'all' || event?.category === filter;
    
    const matchesDateRange = dateRange[0] && dateRange[1] ? 
      moment(event.date).isBetween(dateRange[0], dateRange[1], 'day', '[]') : true;

    return matchesSearch && matchesFilter && matchesDateRange;
  });

  const handleCardClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleAddEvent = async (values) => {
    setIsLoading(true);
    try {
      const seller_id = values.seller_id;
      
      if (!seller_id || !isValidObjectId(seller_id)) {
        throw new Error('Invalid seller ID format. Must be a valid 24-character hex string.');
      }

      const eventData = {
        seller_id,
        slug: values.slug?.trim(),
        title: values.title?.trim(),
        description: values.description?.trim() || '',
        category: values.category,
        location: values.location,
        date: values.date ? moment(values.date).format('YYYY-MM-DD') : null,
        time: values.time?.trim() || '',
        total_tickets: Number(values.total_tickets),
        price_per_ticket: Number(values.price_per_ticket)
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to create event');
      }

      message.success('Event created successfully');
      fetchEvents();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error creating event:', error);
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateEvent = async (values) => {
    setIsLoading(true);
    try {
      const seller_id = values.seller_id;
      
      if (!seller_id || !isValidObjectId(seller_id)) {
        throw new Error('Invalid seller ID format. Must be a valid 24-character hex string.');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/events/${editingEvent._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...values,
          seller_id,
          slug: values.slug?.trim(),
          date: values.date.toISOString()
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update event');
      }
      
      message.success('Event updated successfully');
      fetchEvents();
      setIsModalVisible(false);
      setEditingEvent(null);
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    if (!window.confirm('Are you sure you want to delete this event?')) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/events/${eventId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
      });
      
      if (!response.ok) throw new Error('Failed to delete event');
      
      message.success('Event deleted successfully');
      fetchEvents();
    } catch (error) {
      message.error(error.message);
    }
  };

  const showModal = (event = null) => {
    setEditingEvent(event);
    if (event) {
      form.setFieldsValue({
        ...event,
        date: moment(event.date)
      });
    } else {
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleTitleChange = (e) => {
    const title = e.target.value;
    const suggestedSlug = generateSlug(title);
    form.setFieldsValue({ slug: suggestedSlug });
  };

  const columns = [
    {
      title: 'Event Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <a onClick={() => handleCardClick(record._id)}>{text}</a>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: [
        { text: 'Sports', value: 'sports' },
        { text: 'Concerts', value: 'concerts' },
        { text: 'Arts', value: 'arts' },
      ],
      onFilter: (value, record) => record.category === value,
      render: category => (
        <Tag color={
          category === 'sports' ? 'blue' : 
          category === 'concerts' ? 'purple' :
          'green'
        }>
          {category?.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: date => moment(date).format('MMMM D, YYYY'),
    },
    {
      title: 'Price (KSH)',
      dataIndex: 'price_per_ticket',
      key: 'price',
      render: price => price?.toFixed(2),
    },
    {
      title: 'Available Tickets',
      dataIndex: 'total_tickets',
      key: 'tickets',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            type="default" 
            icon={<EditOutlined />}
            onClick={() => showModal(record)}
          >
            Edit
          </Button>
          <Button 
            danger 
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteEvent(record._id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setSortField(sorter.field);
    setSortOrder(sorter.order);
  };

  return (
    <Layout style={{ minHeight: '100vh', display: 'flex', backgroundColor: '#fff', flexDirection: 'column' }}>
      <Navbar />
      <section style={{ paddingTop: '180px' }}></section>
      <Content style={{ 
        padding: '128px 0 48px', 
        margin: '0 auto', 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden'
      }}>
        <div style={{ 
          maxWidth: '1200px', 
          width: '100%',
          padding: '0 24px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            marginBottom: '24px',
            width: '100%'
          }}>
            <Title level={2} style={{ margin: 0 }}>My Events</Title>
            <Button
              type="default"
              onClick={() => showModal()}
              size="large"
              style={{ background: '#1890ff' }}
            >
              Add New Event
            </Button>
          </div>

          <div style={{ 
            background: '#fff', 
            padding: '24px', 
            borderRadius: '8px',
            width: '100%',
            overflow: 'auto'
          }}>
            <Row gutter={24} style={{ marginBottom: '24px' }}>
              <Col xs={24} sm={8}>
                <Input
                  size="large"
                  placeholder="Search events..."
                  prefix={<SearchOutlined />}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Col>
              <Col xs={24} sm={8}>
                <Select
                  size="large"
                  style={{ width: '100%' }}
                  placeholder="Select category"
                  value={filter}
                  onChange={handleFilterChange}
                >
                  <Option value="all">All Categories</Option>
                  <Option value="sports">Sports</Option>
                  <Option value="concerts">Concerts</Option>
                  <Option value="arts">Arts</Option>
                </Select>
              </Col>
              <Col xs={24} sm={8}>
                <DatePicker.RangePicker
                  size="large"
                  style={{ width: '100%' }}
                  value={dateRange}
                  onChange={setDateRange}
                />
              </Col>
            </Row>

            {isLoading ? (
              <div style={{ textAlign: 'center', padding: '50px' }}>
                <Spin size="large" />
              </div>
            ) : error ? (
              <Alert message={error} type="error" showIcon />
            ) : (
              <Table 
                columns={columns} 
                dataSource={filteredEvents}
                rowKey="_id"
                pagination={{ pageSize: 10 }}
                scroll={{ x: true }}
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
              />
            )}
          </div>
        </div>
      </Content>
      <Footer />

      <Modal
        title={editingEvent ? 'Edit Event' : 'Add New Event'}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setEditingEvent(null);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={editingEvent ? handleUpdateEvent : handleAddEvent}
          layout="vertical"
          initialValues={{
            category: 'sports',
            total_tickets: 1,
            price_per_ticket: 1
          }}
        >
          <Form.Item
            name="seller_id"
            label="Seller ID (Temporary)"
            rules={[
              { required: true, message: 'Seller ID is required' },
              {
                pattern: /^[0-9a-fA-F]{24}$/,
                message: 'Must be a valid 24-character hex string'
              }
            ]}
            tooltip="This is a temporary field for testing. Enter a valid MongoDB ObjectId."
          >
            <Input placeholder="Enter 24-character seller ID" />
          </Form.Item>

          <Form.Item
            name="slug"
            label="Slug (Temporary)"
            rules={[
              { required: true, message: 'Slug is required' },
              {
                pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                message: 'Slug must contain only lowercase letters, numbers, and hyphens'
              }
            ]}
            tooltip="This is a temporary field for testing. Enter a URL-friendly slug."
          >
            <Input placeholder="enter-url-friendly-slug" />
          </Form.Item>

          <Form.Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: 'Title is required' },
              { whitespace: true, message: 'Title cannot be empty' }
            ]}
          >
            <Input onChange={handleTitleChange} />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter event description' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select>
              <Option value="sports">Sports</Option>
              <Option value="concerts">Concerts</Option>
              <Option value="arts">Arts</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="location"
            label="Location"
            rules={[{ required: true, message: 'Please select a location' }]}
          >
            <Select>
              {validLocations.map(location => (
                <Option key={location} value={location}>{location}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: 'Please select a date' }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="time"
            label="Time"
            rules={[{ required: true, message: 'Please enter event time' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="total_tickets"
            label="Total Tickets"
            rules={[{ required: true, message: 'Please enter total tickets' }]}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            name="price_per_ticket"
            label="Price per Ticket (KSH)"
            rules={[{ required: true, message: 'Please enter ticket price' }]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item>
            <Button type="default" htmlType="submit" loading={isLoading} block>
              {editingEvent ? 'Update Event' : 'Create Event'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
}

export default Sellers;
