import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import './Navbar.css';
import logo from '../../assets/logo.png';


const { Header } = Layout;
const NAVBAR_HEIGHT = 64;

const Navbar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();

    const menuItems = [
        { key: '/', label: 'HOME' },
        { key: '/about#hero', label: 'ABOUT' },
        { 
            key: '/services',
            label: 'SERVICES',
            children: [
                { key: '/brand-experience', label: 'BRAND EXPERIENCE' },
                { key: '/private-celebrations', label: 'PRIVATE CELEBRATIONS' },
                { key: '/gifting-concierge', label: 'GIFTING CONCIERGE' }
            ]
        },
        //{ key: '/catalogue-display', label: 'CATALOGUE' },
        { key: '/contact', label: 'CONTACT' },
    ];

    return (
        <Header className="custom-navbar">
            <div className="logo">
                <Link to="/">
                    <img 
                        src={logo} 
                        alt="Zamari Events Logo" 
                    />
                </Link>
            </div>
            
            <div className="desktop-menu">
                {menuItems.map(item => (
                    item.children ? (
                        <div key={item.key} className="submenu-container">
                            <span className="submenu-trigger">{item.label}</span>
                            <div className="submenu">
                                {item.children.map(child => (
                                    <Link 
                                        key={child.key} 
                                        to={child.key}
                                        className={location.pathname === child.key ? 'active' : ''}
                                    >
                                        {child.label}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <Link 
                            key={item.key} 
                            to={item.key}
                            className={location.pathname === item.key ? 'active' : ''}
                        >
                            {item.label}
                        </Link>
                    )
                ))}
            </div>

            <div className="menu-right-space">
                <Link to="/contact" className="quote-button">
                    GET QUOTE
                </Link>
            </div>

            <Button 
                type="text" 
                icon={<MenuOutlined />} 
                onClick={() => setDrawerOpen(true)}
                className="menu-trigger"
            />

            <Drawer
                title="Menu"
                placement="right"
                onClose={() => setDrawerOpen(false)}
                open={drawerOpen}
                className="mobile-drawer"
            >
                <Menu 
                    mode="vertical"
                    selectedKeys={[location.pathname]}
                >
                    {menuItems.map(item => (
                        item.children ? (
                            <Menu.SubMenu key={item.key} title={item.label}>
                                {item.children.map(child => (
                                    <Menu.Item key={child.key}>
                                        <Link to={child.key} onClick={() => setDrawerOpen(false)}>
                                            {child.label}
                                        </Link>
                                    </Menu.Item>
                                ))}
                            </Menu.SubMenu>
                        ) : (
                            <Menu.Item key={item.key}>
                                <Link to={item.key} onClick={() => setDrawerOpen(false)}>
                                    {item.label}
                                </Link>
                            </Menu.Item>
                        )
                    ))}
                    <Menu.Item key="/quote">
                        <Link to="/quote" onClick={() => setDrawerOpen(false)} className="quote-button">
                            GET QUOTE
                        </Link>
                    </Menu.Item>
                </Menu>
            </Drawer>
        </Header>
    );
};

export default Navbar;
