import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { api } from './state/api';
import Navbar from './components/Navbar/Navbar.jsx';
import Home from './pages/index';
import Events from './pages/Events.jsx';
import EventDetails from './pages/event/[slug]';
import Login from './components/Auth/login';
import Signup from './components/Auth/signup';
import Contact from './pages/contact';
import BlogPost from './pages/blog/[slug]';
import Sellercart from './pages/sellers';
import Checkout from './pages/Checkout';
import 'antd/dist/reset.css';
import TestCheckout from './pages/TestCheckout';
import './styles/globals.css';
import About from './pages/About';
import BrandExperience from './pages/BrandExperience';
import PrivateCelebrations from './pages/PrivateCelebrations';
import GiftingConcierge from './pages/GiftingConcierge';
import Blog from './pages/blog.jsx';
import Catalogue from './pages/Catalogue';
import CatalogueDisplay from './pages/CatalogueDisplay';

const { Content } = Layout;

// Configure the Redux store
const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefault) => 
        getDefault().concat(api.middleware),
});

// Create a wrapper component that handles the scroll behavior
function ScrollWrapper({ children }) {
    const location = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    
    // Modify the Layout's Content component with proper margin
    const childrenWithProps = React.Children.map(children, child => {
        if (child.type === Layout) {
            return React.cloneElement(child, {
                children: React.Children.map(child.props.children, layoutChild => {
                    if (layoutChild.type === Content) {
                        return React.cloneElement(layoutChild, {
                            style: {
                                ...layoutChild.props.style,
                                marginTop: location.pathname === '/' ? '0' : '64px'
                            }
                        });
                    }
                    return layoutChild;
                })
            });
        }
        return child;
    });
    
    return childrenWithProps;
}

function App() {
    return (
        <Provider store={store}>
            <div className="w-full overflow-x-hidden">
                <BrowserRouter>
                    <ScrollWrapper>
                        <Layout>
                            <Navbar />
                            <Content style={{ 
                                minHeight: '150vh'
                            }}>
                                <Routes>
                                    {/* Public routes */}
                                    <Route path="/" element={
                                        <>
                                            <Home />
                                            <div id="top-anchor" />
                                        </>
                                    } />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/signup" element={<Signup />} />
                                    <Route path="/about" element={<About />} />

                                    <Route path="/events" element={<Events />} />
                                    <Route path="/event/:slug" element={<EventDetails />} />
                                    <Route path="/contact" element={<Contact />}/>
                                    <Route path="/blog/:slug" element={<BlogPost />} />
                                    <Route path="/event/:slug" element={<EventDetails />} />
                                    {/* Routes that may need protection */}
                                    <Route path="/sellers" element={<Sellercart />} />
                                    <Route path="/checkout" element={<Checkout />} />
                                    <Route path="/test-checkout" element={<TestCheckout />} />
                                    <Route path="/brand-experience" element={<BrandExperience />} />
                                    <Route path="/private-celebrations" element={<PrivateCelebrations />} />
                                    <Route path="/gifting-concierge" element={<GiftingConcierge />} />  
                                    <Route path="/blog" element={<Blog />} />
                                    <Route path="/catalogue" element={<Catalogue />} />
                                    <Route path="/catalogue-display" element={<CatalogueDisplay />} />
                                </Routes>
                            </Content>
                        </Layout>
                    </ScrollWrapper>
                </BrowserRouter>
            </div>
        </Provider>
    );
}

export default App;