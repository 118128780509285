import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Row, Col, Typography, Spin, Alert, Space, Tag, Button, message, Card, Image, Divider, ConfigProvider } from 'antd';
import { ShoppingCartOutlined, CalendarOutlined, EnvironmentOutlined, TeamOutlined } from '@ant-design/icons';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const EventDetails = () => {
    const { slug } = useParams();
    const [event, setEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await fetch('/api/events');
                if (!response.ok) throw new Error('Failed to fetch event');
                const events = await response.json();
                const matchingEvent = events.find(event => event.slug === slug);
                if (!matchingEvent) throw new Error('Event not found');
                setEvent(matchingEvent);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEventDetails();
    }, [slug]);

    const addToCart = (event) => {
        try {
            const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
            
            const eventWithPrice = {
                ...event,
                id: event._id,
                price: event.price_per_ticket,
                title: event.title,
                quantity: 1,
                totalAmount: event.price_per_ticket
            };
            
            const existingProduct = existingCart.find(item => item.id === eventWithPrice.id);
            
            let updatedCart;
            if (existingProduct) {
                updatedCart = existingCart.map(cartItem =>
                    cartItem.id === eventWithPrice.id
                        ? { 
                            ...cartItem, 
                            quantity: cartItem.quantity + 1, 
                            totalAmount: cartItem.price * (cartItem.quantity + 1) 
                        }
                        : cartItem
                );
                messageApi.success('Quantity updated in cart!');
            } else {
                updatedCart = [...existingCart, eventWithPrice];
                messageApi.success('Added to cart successfully!');
            }
            
            localStorage.setItem('cart', JSON.stringify(updatedCart));
        } catch (err) {
            messageApi.error('Failed to add to cart');
            console.error(err);
        }
    };

    return (
        <ConfigProvider>
            {contextHolder}
            <Layout style={{ 
                minHeight: '100vh', 
                background: '#f5f5f5',
                fontFamily: 'Balerno, sans-serif'
            }}>
                <Navbar />
                <Content style={{ padding: '24px', marginTop: '64px' }}>
                    {isLoading ? (
                        <Row justify="center" style={{ padding: '48px' }}>
                            <Spin size="large" />
                        </Row>
                    ) : error ? (
                        <Alert
                            message="Error"
                            description={error}
                            type="error"
                            showIcon
                            style={{ maxWidth: '800px', margin: '0 auto' }}
                        />
                    ) : event ? (
                        <Row justify="center" style={{ paddingTop: '124px' }}>
                            <Col xs={24} sm={20} md={16} lg={12}>
                                <Card 
                                    bordered={false}
                                    style={{ 
                                        borderRadius: '12px',
                                        boxShadow: 'none',
                                        cursor: 'default',
                                        backgroundColor: '#f8f9fa',
                                        paddingLeft: '10px',
                                        paddingRight: '10px'
                                    }}
                                    className="event-details-card"
                                >
                                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                                        <Image
                                            src={event.image || 'https://via.placeholder.com/800x400'}
                                            alt={event.title}
                                            style={{ 
                                                width: '100%',
                                                borderRadius: '8px',
                                                display: 'block',
                                                margin: '0 auto',
                                                textAlign: 'center'
                                            }}
                                            preview={false}
                                        />

                                        <div style={{ textAlign: 'left' }}>
                                            <Tag color="#dacab4">
                                                {event.category}
                                            </Tag>
                                        </div>

                                        <Title level={2} style={{ 
                                            margin: 0,
                                            color: '#2b2b2b'
                                        }}>
                                            {event.title}
                                        </Title>

                                        <Space split={<Divider type="vertical" />}>
                                            <Space>
                                                <CalendarOutlined style={{ color: '#dacab4' }} />
                                                <Text>{new Date(event.date).toLocaleDateString()} at {event.time}</Text>
                                            </Space>
                                            <Space>
                                                <EnvironmentOutlined style={{ color: '#dacab4' }} />
                                                <Text>{event.location}</Text>
                                            </Space>
                                        </Space>

                                        <div>
                                            <Title level={4} style={{ marginBottom: '16px' }}>About this event</Title>
                                            <Paragraph style={{ 
                                                fontSize: '16px',
                                                lineHeight: '1.8',
                                                color: '#4a4a4a'
                                            }}>
                                                {event.description}
                                            </Paragraph>
                                        </div>

                                        <Card 
                                    bordered={false}
                                    style={{ 
                                        borderRadius: '12px',
                                        boxShadow: 'none',
                                        cursor: 'default',
                                        backgroundColor: '#f8f9fa',
                                        paddingLeft: '10px',
                                        paddingRight: '10px'
                                    }}
                                        >
                                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                                                <Space>
                                                    <TeamOutlined style={{ color: '#dacab4' }} />
                                                    <Text strong>{event.total_tickets} tickets available</Text>
                                                </Space>
                                                
                                                <Title level={2} style={{ 
                                                    margin: 0,
                                                    color: '#dacab4'
                                                }}>
                                                    KSH {event.price_per_ticket.toLocaleString()}
                                                </Title>

                                                <Button 
                                                    onClick={() => addToCart(event)}

                                                >
                                                    Add to Cart
                                                </Button>
                                                <Button 
                                                    href="/events"
                                                >
                                                    Back to Events
                                                </Button>
                                                
                                            </Space>
                                        </Card>
                                    </Space>
                                </Card>
                            </Col>
                        </Row>
                    ) : (
                        <Alert
                            message="Event not found"
                            type="error"
                            showIcon
                            style={{ maxWidth: '800px', margin: '0 auto' }}
                        />
                    )}
                </Content>
                <Footer />
            </Layout>
        </ConfigProvider>
    );
};

export default EventDetails;