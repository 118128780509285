import React from 'react';
import { Layout, Row, Col, Input, Button, Divider, Space, Typography } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined, TikTokOutlined } from '@ant-design/icons';
import logo from '../../assets/logo.png';
import './Footer.css';

const { Footer } = Layout;
const { Text, Title } = Typography;

const AppFooter = () => {
  return (
    <Footer className="footer">
      <div className="footer-container">
        <Row gutter={[48, 48]} justify="space-between">
          <Col xs={24} sm={12} md={8}>
            <img 
              src={logo}
              alt="Tiko Logo" 
              className="footer-logo"
            />
          </Col>
          
          <Col xs={24} sm={12} md={8}>
            <Title level={4} className="section-title">Contact</Title>
            <Text className="contact-text">Phone: +254 795 825 320</Text>
            <Text className="contact-text">Email: eventsbyzamari@gmail.com</Text>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Title level={4} className="section-title">Office</Title>
            <Text className="address-text">
              Kikuyu, Kenya
              <br />
              Nairobi, Kenya
              <br />
              P.O. Box 35410-00100
            </Text>
          </Col>
        </Row>

        <Divider className="footer-divider" />

        <Row justify="space-between" align="middle">
          <Col>
            <Text className="copyright-text">
              © {new Date().getFullYear()} Zamari. All rights reserved.
            </Text>
          </Col>
          <Col>
            <Space size="large">
              <a 
                href="https://www.instagram.com/zamari_events" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="social-link"
              >
                <InstagramOutlined />
              </a>
              <a 
                href="https://www.tiktok.com/@zamari_events" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="social-link"
              >
                <TikTokOutlined />
              </a>
            </Space>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default AppFooter;
