// Add these import statements at the top of your file
import Navbar from '../components/Navbar/Navbar';
import homeImage from '../assets/Pri/gift.jpg';
import { Typography, Button } from 'antd';
import Footer from '../components/Footer/Footer';
import home1 from '../assets/Pri/gift.jpg';


const { Title, Paragraph } = Typography;

// ... existing code ...
const GiftingConcierge = () => {
    return (
        <>
            <Navbar />
            <div className="gifting-page" style={{ backgroundColor: '#fff', fontFamily: 'Balerno, serif' }}>
            <section 
                    className="hero-section" 
                    style={{ 
                        textAlign: 'center', 
                        position: 'relative',
                        padding: '140px 0',
                        color: '#fff',
                        marginTop: '0px',
                        fontFamily: 'Balerno, serif',
                        overflow: 'hidden'
                    }}
                >
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${homeImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'blur(4px)',
                        transform: 'scale(1.1)', // Prevents blur edges from showing
                        zIndex: 1
                    }} />
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <h1 level={1} style={{ marginBottom: '20px', color: '#fff', fontSize: '48px', fontFamily: 'Balerno, serif' }}>
                        Gifting and Room Decor
                        </h1>
                    </div>
                </section>
                    

                <section style={{ padding: '80px 20px', textAlign: 'center' }}>
                <Title level={2} style={{ marginBottom: '40px', color: '#000', fontSize: '36px', fontFamily: 'Balerno, serif' }}>
                        Bespoke Gifting
                    </Title>
                    <Paragraph style={{ 
                        fontSize: '20px', 
                        maxWidth: '800px', 
                        margin: '0 auto', 
                        padding: '0 20px',
                        color: '#000',
                        lineHeight: '1.6',
                        fontFamily: 'Balerno, serif'
                    }}>
                        We will source and supply bespoke gifts and flowers for any celebration i.e. birthday, baby / bridal shower, anniversary, congratulations, appreciate gifts, press drops and campaign shoots. We understand that your relationships make your business. Gifts also create a lifetime of memories for your loved ones, guests at your wedding or private celebration. When it is time to celebrate your loved ones or thank the people you count on everyday, count on us.
                    </Paragraph>
                  
                    <Button 
                        type="text" 
                        size="large"
                        style={{
                            height: '48px',
                            padding: '0 32px',
                            fontSize: '16px',
                            color: 'gold',
                            backgroundColor: 'transparent',
                            border: '1px solid gold',
                            transition: 'all 0.3s ease',
                            fontFamily: 'Balerno, serif'
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 215, 0, 0.1)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                    >
                        BOOK A CONSULTATION
                    </Button>
                </section>
                <section>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                        gap: '20px',
                        padding: '40px 20px',
                        maxWidth: '1200px',
                        margin: '0 auto'
                    }}>
                        <img src={home1} alt="Gifting showcase 1" style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover'
                        }} />
                        
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default GiftingConcierge;
// ... existing code ...