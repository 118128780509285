import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Typography, Spin, Alert, Space, Select, Tag, Button, Badge, Empty, Divider, Popover, Card } from 'antd';
import { ShoppingCartOutlined, DeleteOutlined } from '@ant-design/icons';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useGetCatalogueItemsQuery } from '../state/api';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

// Button styles (reused from Events page)
const buttonStyle = {
    backgroundColor: 'transparent',
    color: '#5f6368',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 500,
    height: '36px',
    padding: '0 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    transition: 'all 0.2s',
    ':hover': {
        backgroundColor: '#f1f3f4',
        color: '#202124'
    }
};

const Catalogue = () => {
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [cart, setCart] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const navigate = useNavigate();

    // Use RTK Query hook with proper destructuring
    const { data: items = [], isLoading, error: queryError } = useGetCatalogueItemsQuery(
        categoryFilter !== 'all' ? categoryFilter : undefined
    );

    // Load cart from localStorage
    useEffect(() => {
        const savedCart = localStorage.getItem('rentalCart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    }, []);

    // Update total amount when cart changes
    useEffect(() => {
        const newTotalAmount = cart.reduce((total, item) => 
            total + (item.price_per_day * item.quantity * item.days), 0);
        setTotalAmount(newTotalAmount);
        localStorage.setItem('rentalCart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (item) => {
        const existingItem = cart.find(cartItem => cartItem._id === item._id);
        
        if (existingItem) {
            setCart(cart.map(cartItem =>
                cartItem._id === item._id
                    ? { ...cartItem, quantity: cartItem.quantity + 1 }
                    : cartItem
            ));
        } else {
            setCart([...cart, { ...item, quantity: 1, days: 1 }]);
        }
    };

    const removeFromCart = (itemId) => {
        setCart(cart.filter(item => item._id !== itemId));
    };

    const updateQuantity = (itemId, quantity) => {
        setCart(cart.map(item =>
            item._id === itemId
                ? { ...item, quantity: Math.max(1, quantity) }
                : item
        ));
    };

    const updateDays = (itemId, days) => {
        setCart(cart.map(item =>
            item._id === itemId
                ? { ...item, days: Math.max(1, days) }
                : item
        ));
    };

    const clearCart = () => {
        setCart([]);
        localStorage.removeItem('rentalCart');
    };

    // Render mini cart content
    const renderMiniCart = () => (
        <div style={{ width: 430, maxHeight: 540, overflow: 'auto', padding: '16px' }}>
            {cart.length > 0 ? (
                <>
                    {cart.map((item) => (
                        <Row key={item._id} align="middle" style={{ marginBottom: 16 }}>
                            <Col flex="auto">
                                <Text strong>{item.name}</Text>
                                <div>
                                    <Text type="secondary">
                                        KSH {item.price_per_day} × {item.quantity} × {item.days} days
                                    </Text>
                                </div>
                                <div style={{ marginTop: 8 }}>
                                    <Space>
                                        <Select
                                            value={item.quantity}
                                            onChange={(value) => updateQuantity(item._id, value)}
                                            style={{ width: 80 }}
                                        >
                                            {[...Array(10)].map((_, i) => (
                                                <Option key={i + 1} value={i + 1}>{i + 1}</Option>
                                            ))}
                                        </Select>
                                        <Select
                                            value={item.days}
                                            onChange={(value) => updateDays(item._id, value)}
                                            style={{ width: 100 }}
                                        >
                                            {[...Array(30)].map((_, i) => (
                                                <Option key={i + 1} value={i + 1}>{i + 1} day{i > 0 ? 's' : ''}</Option>
                                            ))}
                                        </Select>
                                        <Button
                                            type="text"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => removeFromCart(item._id)}
                                        />
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    ))}
                    <Divider />
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Text strong>Total:</Text>
                        </Col>
                        <Col>
                            <Text strong>KSH {totalAmount.toFixed(2)}</Text>
                        </Col>
                    </Row>
                    <Row justify="end" style={{ marginTop: 16 }}>
                        <Space>
                            <Button onClick={clearCart}>Clear Cart</Button>
                            <Button type="primary" onClick={() => navigate('/checkout')}>
                                Checkout
                            </Button>
                        </Space>
                    </Row>
                </>
            ) : (
                <Empty description="Your cart is empty" />
            )}
        </div>
    );

    // Render cart button
    const renderCartButton = () => (
        <Popover 
            content={renderMiniCart()}
            title={<Badge count={cart.length}>Rental Cart</Badge>}
            trigger="click"
            placement="bottomRight"
        >
            <Button 
                style={{
                    position: 'fixed',
                    top: '100px',
                    right: '24px',
                    zIndex: 1000,
                }}
                icon={<ShoppingCartOutlined />}
            >
                <Badge count={cart.length}>
                    KSH {totalAmount.toFixed(2)}
                </Badge>
            </Button>
        </Popover>
    );

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Navbar />
            {renderCartButton()}
            <Content style={{ padding: '24px' }}>
                <Row justify="center" style={{ marginBottom: 32 }}>
                    <Col xs={24} md={16} lg={12}>
                        <Title level={1} style={{ textAlign: 'center' }}>
                            Equipment Catalogue
                        </Title>
                        <Text style={{ display: 'block', textAlign: 'center', fontSize: 18 }}>
                            Browse our selection of professional event equipment for hire
                        </Text>
                    </Col>
                </Row>

                <Row justify="center" style={{ marginBottom: 24 }}>
                    <Col>
                        <Select
                            style={{ width: 200 }}
                            value={categoryFilter}
                            onChange={(value) => setCategoryFilter(value)}
                        >
                            <Option value="all">All Categories</Option>
                            <Option value="tents">Tents</Option>
                            <Option value="chairs">Chairs</Option>
                            <Option value="tables">Tables</Option>
                            <Option value="sound">Sound Equipment</Option>
                            <Option value="lighting">Lighting</Option>
                            <Option value="decor">Decorations</Option>
                        </Select>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {isLoading ? (
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size="large" />
                        </Col>
                    ) : queryError ? (
                        <Col span={24}>
                            <Alert
                                message="Error"
                                description={queryError.message}
                                type="error"
                                showIcon
                            />
                        </Col>
                    ) : (
                        Array.isArray(items) && items.map(item => (
                            <Col xs={24} sm={12} md={8} lg={6} key={item._id}>
                                <Card
                                    hoverable
                                    cover={
                                        <img 
                                            alt={item.name}
                                            src={item.image}
                                            style={{ height: 200, objectFit: 'cover' }}
                                        />
                                    }
                                    actions={[
                                        <Button 
                                            icon={<ShoppingCartOutlined />}
                                            onClick={() => addToCart(item)}
                                        >
                                            Add to Cart
                                        </Button>
                                    ]}
                                >
                                    <Card.Meta
                                        title={item.name}
                                        description={
                                            <>
                                                <Text>KSH {item.price_per_day}/day</Text>
                                                <br />
                                                <Tag color="blue">{item.category}</Tag>
                                                <div style={{ marginTop: 8 }}>
                                                    <Text type="secondary">{item.description}</Text>
                                                </div>
                                                {item.specifications && (
                                                    <div style={{ marginTop: 8 }}>
                                                        {Object.entries(item.specifications).map(([key, value]) => (
                                                            <div key={key}>
                                                                <Text type="secondary">{key}: {value}</Text>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        }
                                    />
                                </Card>
                            </Col>
                        ))
                    )}
                </Row>

                {!isLoading && !queryError && (!items || items.length === 0) && (
                    <Empty description="No items found in this category" />
                )}
            </Content>
            <Footer />
        </Layout>
    );
};

export default Catalogue; 