import React, { useState } from 'react';
import { Layout, Row, Col, Typography, Spin, Alert, Select, Tag, Card } from 'antd';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { useGetCatalogueItemsQuery } from '../state/api';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;

const CatalogueDisplay = () => {
    const [categoryFilter, setCategoryFilter] = useState('all');

    // Use RTK Query hook with proper destructuring
    const { data: items = [], isLoading, error: queryError } = useGetCatalogueItemsQuery(
        categoryFilter !== 'all' ? categoryFilter : undefined
    );

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Navbar />
            <Content style={{ padding: '24px' }}>
                <Row justify="center" style={{ marginBottom: 32 }}>
                    <Col xs={24} md={16} lg={12}>
                        <Title level={1} style={{ textAlign: 'center' }}>
                            Equipment Catalogue
                        </Title>
                        <Text style={{ display: 'block', textAlign: 'center', fontSize: 18 }}>
                            Browse our selection of professional event equipment for hire
                        </Text>
                    </Col>
                </Row>

                <Row justify="center" style={{ marginBottom: 24 }}>
                    <Col>
                        <Select
                            style={{ width: 200 }}
                            value={categoryFilter}
                            onChange={(value) => setCategoryFilter(value)}
                        >
                            <Option value="all">All Categories</Option>
                            <Option value="tents">Tents</Option>
                            <Option value="chairs">Chairs</Option>
                            <Option value="tables">Tables</Option>
                            <Option value="sound">Sound Equipment</Option>
                            <Option value="lighting">Lighting</Option>
                            <Option value="decor">Decorations</Option>
                        </Select>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    {isLoading ? (
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size="large" />
                        </Col>
                    ) : queryError ? (
                        <Col span={24}>
                            <Alert
                                message="Error"
                                description={queryError.message}
                                type="error"
                                showIcon
                            />
                        </Col>
                    ) : (
                        Array.isArray(items) && items.map(item => (
                            <Col xs={24} sm={12} md={8} lg={6} key={item._id}>
                                <Card
                                    hoverable
                                    cover={
                                        <img 
                                            alt={item.name}
                                            src={item.image}
                                            style={{ height: 200, objectFit: 'cover' }}
                                        />
                                    }
                                >
                                    <Card.Meta
                                        title={item.name}
                                        description={
                                            <>
                                                <Text>KSH {item.price_per_day}/day</Text>
                                                <br />
                                                <Tag color="blue">{item.category}</Tag>
                                                <div style={{ marginTop: 8 }}>
                                                    <Text type="secondary">{item.description}</Text>
                                                </div>
                                                {item.specifications && (
                                                    <div style={{ marginTop: 8 }}>
                                                        {Object.entries(item.specifications).map(([key, value]) => (
                                                            <div key={key}>
                                                                <Text type="secondary">{key}: {value}</Text>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        }
                                    />
                                </Card>
                            </Col>
                        ))
                    )}
                </Row>
            </Content>
            <Footer />
        </Layout>
    );
};

export default CatalogueDisplay; 