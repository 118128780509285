import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Typography, Spin, Alert, Space, List, Select, DatePicker, Slider, Tag, Button, Badge, Empty, Divider, Popover } from 'antd';
import { CalendarOutlined, ShoppingCartOutlined, DeleteOutlined, DollarOutlined } from '@ant-design/icons';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import EventsCard2 from '../components/EventCard/EventsCard2'; // Import the new component
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

// Custom button styles
const buttonStyle = {
  backgroundColor: 'transparent',
  color: '#5f6368',
  border: 'none',
  boxShadow: 'none',
  fontWeight: 500,
  height: '36px',
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  transition: 'all 0.2s',
  ':hover': {
    backgroundColor: '#f1f3f4',
    color: '#202124'
  }
};

const smallButtonStyle = {
  ...buttonStyle,
  height: '32px',
  padding: '0 12px',
  fontSize: '14px'
};

const Events = () => {
    const [events, setEvents] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [blogsLoading, setBlogsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [blogsError, setBlogsError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('all');
    const [dateRange, setDateRange] = useState(null);
    const [priceRange, setPriceRange] = useState([0, 100000]); // Assuming max price is 100,000 KSH
    const [cart, setCart] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/events`);
                if (!response.ok) throw new Error('Failed to fetch events');
                const data = await response.json();
                setEvents(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEvents();
    }, []);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/blogs`);
                if (!response.ok) throw new Error('Failed to fetch blogs');
                const data = await response.json();
                setBlogs(data);
            } catch (err) {
                setBlogsError(err.message);
            } finally {
                setBlogsLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    useEffect(() => {
        const newTotalAmount = cart.reduce((total, cartItem) => total + cartItem.totalAmount, 0);
        setTotalAmount(newTotalAmount);
    }, [cart]);

    useEffect(() => {
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    }, []);

    const filteredEvents = events.filter(event => {
        const matchesSearch = event?.title?.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = categoryFilter === 'all' || event.category === categoryFilter;
        const matchesDateRange = !dateRange || (
            new Date(event.date) >= dateRange[0].startOf('day') &&
            new Date(event.date) <= dateRange[1].endOf('day')
        );
        const matchesPrice = event.price_per_ticket >= priceRange[0] && 
                           event.price_per_ticket <= priceRange[1];

        return matchesSearch && matchesCategory && matchesDateRange && matchesPrice;
    });

    const addProductToCart = (event) => {
        const eventWithPrice = {
            ...event,
            id: event._id,
            price: event.price_per_ticket,
            title: event.title,
            quantity: 1,
            totalAmount: event.price_per_ticket
        };

        const existingProduct = cart.find(item => item.id === eventWithPrice.id);
        
        let updatedCart;
        if (existingProduct) {
            updatedCart = cart.map(cartItem =>
                cartItem.id === eventWithPrice.id
                    ? { 
                        ...cartItem, 
                        quantity: cartItem.quantity + 1, 
                        totalAmount: cartItem.price * (cartItem.quantity + 1) 
                    }
                    : cartItem
            );
        } else {
            updatedCart = [...cart, eventWithPrice];
        }
        
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const renderEventCard = (event) => (
        <EventsCard2 
            event={event} 
            key={event.id}
            actions={[
                <Button 
                    style={smallButtonStyle}
                    icon={<ShoppingCartOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        addProductToCart(event);
                    }}
                    size="small"
                >
                    Add to Cart
                </Button>
            ]}
        />
    );

    const renderMiniCart = () => (
        <div 
            style={{ 
                width: 430,
                maxHeight: 540,
                overflow: 'auto',
                backgroundColor: '#fff',
                boxShadow: '0 1px 2px 0 rgba(60,64,67,0.3), 0 2px 6px 2px rgba(60,64,67,0.15)',
                borderRadius: '8px',
                padding: '16px'
            }}
        >
            {cart.length > 0 ? (
                <>
                    {cart.map((item) => (
                        <Row 
                            key={item.id} 
                            justify="space-between" 
                            align="middle"
                            style={{ 
                                marginBottom: 12,
                                padding: '12px 8px',
                                borderBottom: '1px solid #f0f0f0',
                                transition: 'background-color 0.2s',
                                ':hover': {
                                    backgroundColor: '#f8f9fa'
                                }
                            }}
                        >
                            <Col flex="auto">
                                <Text strong style={{ display: 'block', color: '#202124' }}>{item.title}</Text>
                                <Text type="secondary" style={{ fontSize: '13px' }}>
                                    {item.quantity} × KSH {item.price?.toFixed(2)}
                                </Text>
                            </Col>
                            <Col>
                                <Button 
                                    style={smallButtonStyle}
                                    icon={<DeleteOutlined />}
                                    size="small"
                                    onClick={() => removeProductFromCart(item)}
                                />
                            </Col>
                        </Row>
                    ))}
                    
                    <Divider style={{ margin: '16px 0' }} />
                    
                    <Row justify="space-between" align="middle" style={{ padding: '8px 0' }}>
                        <Col>
                            <Text strong style={{ color: '#202124' }}>Total:</Text>
                        </Col>
                        <Col>
                            <Text strong style={{ color: '#1a73e8' }}>KSH {totalAmount.toFixed(2)}</Text>
                        </Col>
                    </Row>
                    
                    <Row gutter={[12, 12]} style={{ marginTop: 16 }}>
                        <Col span={12}>
                            <Button 
                                style={{
                                    ...smallButtonStyle,
                                    width: '100%',
                                    border: '1px solid #dadce0',
                                    borderRadius: '4px'
                                }}
                                onClick={clearCart}
                            >
                                Clear
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button 
                                style={{
                                    width: '100%',
                                    backgroundColor: '#1a73e8',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: '4px',
                                    ':hover': {
                                        backgroundColor: '#1557b0'
                                    }
                                }}
                                onClick={() => navigate('/checkout')}
                            >
                                Checkout
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Your cart is empty"
                    style={{ 
                        margin: '32px 0',
                        color: '#5f6368'
                    }}
                />
            )}
        </div>
    );

    const renderCartButton = () => (
        <Popover 
            content={renderMiniCart()}
            title={
                <div style={{ textAlign: 'center' }}>
                    <Badge count={cart.length}>Shopping Cart</Badge>
                </div>
            }
            trigger="click"
            placement="bottomRight"
            overlayStyle={{ 
                width: 430,
            }}
            overlayInnerStyle={{
                padding: 0,
            }}
        >
            <Button 
                style={{
                    position: 'fixed',
                    top: '100px',
                    right: '24px',
                    zIndex: 1000,
                    backgroundColor: '#fff',
                    border: '1px solid #dadce0',
                    borderRadius: '8px',
                    color: '#3c4043',
                    height: '53px',
                    padding: '0 26px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    boxShadow: '0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)',
                    transition: 'box-shadow 0.2s',
                    ':hover': {
                        backgroundColor: '#fff',
                        boxShadow: '0 1px 3px 0 rgba(60,64,67,0.3), 0 4px 8px 3px rgba(60,64,67,0.15)'
                    }
                }}
                icon={<ShoppingCartOutlined style={{ fontSize: '22px' }} />}
            >
                <Badge 
                    count={cart.length}
                    style={{
                        backgroundColor: '#1a73e8',
                        boxShadow: 'none'
                    }}
                >
                    <span style={{ fontWeight: 500 }}>KSH {totalAmount.toFixed(2)}</span>
                </Badge>
            </Button>
        </Popover>
    );

    const removeProductFromCart = (event) => {
        const updatedCart = cart.filter(cartItem => cartItem.id !== event.id);
        setCart(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    const clearCart = () => {
        setCart([]);
        localStorage.removeItem('cart');
    };

    const handleSearch = (value) => {
        setSearchTerm(value);
    };

    return (
        <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', fontFamily: 'Balerno' }}>
            <Navbar />
            {renderCartButton()}
            <Content style={{ 
                width: '100%', 
                overflow: 'hidden', 
                paddingBottom: '70px',
                paddingLeft: '10px',
                paddingRight: '10px'
            }}>
                <Row justify="center" align="middle" style={{ padding: '48px 24px 24px' }}>
                    <Col xs={24} sm={20} md={16} lg={12}>
                        <Space direction="vertical" size="large" style={{ width: '100%', textAlign: 'center' }}>
                            <Title level={1} style={{ fontFamily: 'Balerno', fontStyle: 'bold' }}>
                                <strong>Event Ticketing</strong>
                            </Title>
                            <Text style={{ fontSize: '24px', color: '#black', fontFamily: 'Balerno' }}>
                                <ul style={{ listStyle: 'none', padding: 0, margin: 0, textAlign: 'left' }}>
                                    <li>• Sign up to Create Tickets for your Events</li>
                                    <li>• Discover Amazing Events and Purchase Tickets Directly</li>
                                </ul>
                            </Text>
                        </Space>
                    </Col>
                </Row>

                

                <Row justify="center" align="middle" style={{ padding: '0 16px 24px' }}>
                    <Col xs={24} sm={20} md={16} lg={12}>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Space wrap style={{ width: '100%', justifyContent: 'center' }}>
                                <Select
                                    style={{ width: 120 }}
                                    value={categoryFilter}
                                    onChange={(value) => setCategoryFilter(value)}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Option value="all">All Categories</Option>
                                    <Option value="sports">Sports</Option>
                                    <Option value="concerts">Concerts</Option>
                                    <Option value="arts">Arts</Option>
                                </Select>
                                <RangePicker
                                    onChange={(dates) => setDateRange(dates)}
                                />
                                <div style={{ width: 200 }}>
                                    <Text>Price Range (KSH)</Text>
                                    <Slider
                                        range
                                        value={priceRange}
                                        onChange={setPriceRange}
                                        min={0}
                                        max={5000}
                                        step={500}
                                    />
                                </div>
                            </Space>
                        </Space>
                    </Col>
                </Row>

                <Row gutter={[16, 16]} style={{ padding: '0 16px', margin: 0 }}>
                    <Col 
                        xs={24} 
                        xl={18} 
                        style={{ 
                            padding: '24px',
                            backgroundColor: '#f7f7f7',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        {isLoading ? (
                            <Row justify="center" style={{ padding: '64px 0' }}>
                                <Spin size="large" />
                            </Row>
                        ) : error ? (
                            <Alert
                                message="Error"
                                description={error}
                                type="error"
                                showIcon
                                style={{ 
                                    fontFamily: 'Balerno',
                                    margin: '24px 0'
                                }}
                            />
                        ) : (
                            <>
                                <Row 
                                    gutter={[24, 24]} 
                                    justify="start"
                                    align="center"
                                    style={{ 
                                        margin: 0,
                                        minHeight: '400px'
                                    }}
                                >
                                    {filteredEvents.map(renderEventCard)}
                                </Row>
                                {filteredEvents.length === 0 && (
                                    <Empty
                                        description="No events found"
                                        style={{ 
                                            margin: '48px 0',
                                            color: '#5f6368'
                                        }}
                                    />
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Content>
            <Footer />
        </Layout>
    );
};

export default Events;
