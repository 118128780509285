import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import catalogueData from '../data/catalogueItems.json';

export const api = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL || "http://localhost:9000/",
  }),
  tagTypes: ["Users", "Events", "Blogs", "Catalogue"],
  
  endpoints: (builder) => ({
    // User endpoints
    getUsers: builder.query({
      query: () => ({
        url: "users",
        method: "GET"
      }),
      providesTags: ["Users"],
    }),
    createUser: builder.mutation({
      query: (userData) => ({
        url: "users",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation({
      query: ({ id, ...userData }) => ({
        url: `users/${id}`,
        method: "PUT",
        body: userData,
      }),
      invalidatesTags: ["Users"],
    }),

    // Event endpoints
    getEvents: builder.query({
      query: () => ({
        url: "api/events",
        method: "GET"
      }),
      providesTags: ["Events"],
    }),
    createEvent: builder.mutation({
      query: (newEvent) => ({
        url: "api/events",
        method: "POST",
        body: newEvent,
      }),
      invalidatesTags: ["Events"],
    }),
    updateEvent: builder.mutation({
      query: ({ id, ...eventData }) => ({
        url: `api/events/${id}`,
        method: "PUT",
        body: eventData,
      }),
      invalidatesTags: ["Events"],
    }),

    // Blog endpoints
    getBlogs: builder.query({
      query: () => ({
        url: "api/blogs",
        method: "GET"
      }),
      providesTags: ["Blogs"],
    }),
    getBlogBySlug: builder.query({
      query: (slug) => ({
        url: `blogs/${slug}`,
        method: "GET"
      }),
      providesTags: ["Blogs"],
    }),
    createBlog: builder.mutation({
      query: (blogData) => ({
        url: "blogs",
        method: "POST",
        body: blogData,
      }),
      invalidatesTags: ["Blogs"],
    }),
    updateBlog: builder.mutation({
      query: ({ slug, ...blogData }) => ({
        url: `blogs/${slug}`,
        method: "PUT",
        body: blogData,
      }),
      invalidatesTags: ["Blogs"],
    }),
    deleteBlog: builder.mutation({
      query: (slug) => ({
        url: `blogs/${slug}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Blogs"],
    }),

    // Mock Catalogue endpoints
    getCatalogueItems: builder.query({
      queryFn: (category) => {
        try {
          const items = catalogueData.items;
          const filteredItems = category && category !== 'all'
            ? items.filter(item => item.category === category)
            : items;
          return { data: filteredItems };
        } catch (error) {
          return { error: { status: 500, data: error.message } };
        }
      },
    }),
    getCatalogueItemById: builder.query({
      queryFn: (id) => {
        try {
          const item = catalogueData.items.find(item => item._id === id);
          if (!item) {
            return { error: { status: 404, data: 'Item not found' } };
          }
          return { data: item };
        } catch (error) {
          return { error: { status: 500, data: error.message } };
        }
      },
    }),
  }),
});

// Export hooks for usage in components
export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useGetBlogsQuery,
  useGetBlogBySlugQuery,
  useCreateBlogMutation,
  useUpdateBlogMutation,
  useDeleteBlogMutation,
  useGetCatalogueItemsQuery,
  useGetCatalogueItemByIdQuery,
} = api;