// Add these import statements at the top of your file
import Navbar from '../components/Navbar/Navbar';
import hero from '../assets/Pri/pri1.jpg';
import { Typography, Button } from 'antd';
import Footer from '../components/Footer/Footer';
import home1 from '../assets/Pri/birth.jpg';
import home2 from '../assets/Pri/bri1.jpg';
import home3 from '../assets/Pri/pic.jpg';
import home4 from '../assets/picnics/pic2.jpg';
import home6 from '../assets/picnics/pic10.jpg';
import home5 from '../assets/garland/gar1.jpg';
import home7 from '../assets/garland/gar21.jpg';


const { Title, Paragraph } = Typography;

// ... existing code ...
const PrivateCelebrations = () => {
    return (
        
        <>
        
            <Navbar />
            <div className="gifting-page" style={{ backgroundColor: '#fff', fontFamily: 'Balerno, serif' }}>
                <section 
                    className="hero-section" 
                    style={{ 
                        textAlign: 'center', 
                        position: 'relative',
                        padding: '140px 0',
                        color: '#fff',
                        marginTop: '0px',
                        fontFamily: 'Balerno, serif',
                        overflow: 'hidden'
                    }}
                >
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${hero})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'blur(4px)',
                        transform: 'scale(1.1)', // Prevents blur edges from showing
                        zIndex: 1
                    }} />
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <h1 level={1} style={{ marginBottom: '20px', color: '#fff', fontSize: '48px', fontFamily: 'Balerno, serif' }}>
                            Private Celebrations
                        </h1>
                    </div>
                </section>

                <section style={{ padding: '80px 20px', textAlign: 'center' }}>
                    <Title level={2} style={{ marginBottom: '40px', color: '#000', fontSize: '36px', fontFamily: 'Balerno, serif' }}>
                        Bespoke Event Planning
                    </Title>
                    <Paragraph style={{
                        fontSize: '18px',
                        maxWidth: '800px',
                        margin: '0 auto 40px',
                        lineHeight: '1.6',
                        color: '#000',
                        fontFamily: 'Balerno, serif'
                    }}>
                        Our promise to you is to execute your vision throughout every detail and element. Whether you are planning a fabulous private dinner, birthday, graduation, engagement, bridal/baby shower or an anniversary celebration, you can rely on our experience and passion for a personalised & memorable event.
                    </Paragraph>
                    <Button 
                        type="text" 
                        size="large"
                        href="/contact"
                        style={{
                            height: '48px',
                            padding: '0 32px',
                            fontSize: '16px',
                            color: 'gold',
                            backgroundColor: 'transparent',
                            border: '1px solid gold',
                            transition: 'all 0.3s ease',
                            fontFamily: 'Balerno, serif'
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 215, 0, 0.1)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                    >
                        BOOK A CONSULTATION
                    </Button>
                </section>

                {/* Birthday Section */}
                <section style={{ padding: '40px 20px', textAlign: 'center' }}>
                    <Title level={3} style={{ marginBottom: '20px', color: '#000', fontSize: '30px', fontFamily: 'Balerno, serif' }}>
                        Social Events (Birthday, Engagement, Bridal/Baby Shower, Anniversary)
                    </Title>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                        gap: '20px',
                        padding: '20px 0',
                        maxWidth: '1200px',
                        margin: '0 auto'
                    }}>
                        <img src={home1} alt="Birthday showcase 1" style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover'
                        }} />
                        <img src={home2} alt="Birthday showcase 2" style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover'
                        }} />
                    </div>
                </section>

                {/* Picnic Section */}
                <section style={{ padding: '40px 20px', textAlign: 'center' }}>
                    <Title level={3} style={{ marginBottom: '20px', color: '#000', fontSize: '30px', fontFamily: 'Balerno, serif' }}>
                        Picnic Events
                    </Title>
                    <Paragraph style={{ 
                        fontSize: '18px', 
                        maxWidth: '800px', 
                        margin: '0 auto', 
                        color: '#000',
                        lineHeight: '1.6',
                        fontFamily: 'Balerno, serif'
                    }}>
                        Enjoy a delightful picnic experience with our expertly curated setups in beautiful outdoor settings.
                    </Paragraph>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                        gap: '20px',
                        padding: '20px 0',
                        maxWidth: '1200px',
                        margin: '0 auto'
                    }}>
                        <img src={home3} alt="Picnic showcase 1" style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover'
                        }} />
                        <img src={home4} alt="Picnic showcase 1" style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover'
                        }} />
                        <img src={home6} alt="Outdoor showcase 1" style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover'
                        }} />
                        
                    </div>
                </section>

                {/* Outdoor Events Section */}
                <section style={{ padding: '40px 20px', textAlign: 'center' }}>
                    <Title level={3} style={{ marginBottom: '20px', color: '#000', fontSize: '30px', fontFamily: 'Balerno, serif' }}>
                        Balloon Backdrops
                    </Title>
                    <Paragraph style={{ 
                        fontSize: '18px', 
                        maxWidth: '800px', 
                        margin: '0 auto', 
                        color: '#000',
                        lineHeight: '1.6',
                        fontFamily: 'Balerno, serif'
                    }}>
                        Host an unforgettable outdoor event with our creative and unique setups tailored to your vision.
                    </Paragraph>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                        gap: '20px',
                        padding: '20px 0',
                        maxWidth: '1200px',
                        margin: '0 auto'
                    }}>
                        <img src={home5} alt="Outdoor showcase 1" style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover'
                        }} />
                        <img src={home7} alt="Outdoor showcase 1" style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover'
                        }} />
                        
                        
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default PrivateCelebrations;
// ... existing code ...