import React from 'react';
import { Typography, Button, Card, Row, Col } from 'antd';
import homeImage from '../assets/img/profile.jpg';
import aboutImage from '../assets/img/About.jpg';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

const { Title, Paragraph } = Typography;

const About = () => {
    return (
        <>
            <Navbar />
            <div className="about-page" style={{ backgroundColor: '#fff', fontFamily: 'Balerno, serif' }}>
                <section id="hero"
                    className="hero-section" 
                    style={{ 
                        textAlign: 'center', 
                        backgroundImage: `url(${aboutImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        padding: window.innerWidth <= 768 ? '38px 0' : '140px 0',
                        color: '#fff',
                        marginTop: '0px',
                        fontFamily: 'Balerno, serif',
                        position: 'relative',
                        overflow: 'hidden'
                    }}
                >
                    <div style={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        backdropFilter: 'blur(4px)',
                        WebkitBackdropFilter: 'blur(4px)' // For Safari support
                    }} />
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <h1 level={1} style={{ marginBottom: '20px', color: '#fff', fontSize: '48px', fontFamily: 'Balerno, serif' }}>
                            About Zamari Events
                        </h1>
                        <Paragraph style={{ 
                            fontSize: '20px', 
                            maxWidth: '800px', 
                            margin: '0 auto', 
                            padding: '0 20px',
                            color: '#fff',
                            lineHeight: '1.6',
                            fontFamily: 'Balerno, serif'
                        }}>
                            Your premier destination for discovering and booking unforgettable events and experiences
                        </Paragraph>
                    </div>
                </section>

                


                <section style={{ display: 'flex', flexDirection: 'column', gap: '2rem', maxWidth: '800px', margin: '0 auto', paddingTop: '48px', fontFamily: 'Balerno, serif' }}>
                    <Title level={2} style={{ textAlign: 'center', marginBottom: '24px', fontFamily: 'Balerno, serif' }}>
                        How We Work
                    </Title>
                    <Card style={{ border: 'none', boxShadow: 'none', fontFamily: 'Balerno, serif' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', fontFamily: 'Balerno, serif' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexDirection: 'column', fontFamily: 'Balerno, serif' }}>
                                <div style={{ textAlign: 'center', fontFamily: 'Balerno, serif', fontSize: '24px' }}>
                                    <strong>1. Let's Meet</strong>
                                </div>
                                <div style={{ fontFamily: 'Balerno, serif', fontSize: '20px' }}>
                                    We start with a friendly chat to understand what kind of event you want. Tell us your ideas and dreams, and we'll show you what we can do to help make them real.
                                </div>
                            </div>
                            <div 
                                style={{ color: '#CDAD72', fontSize: '24px', transition: 'color 0.3s, transform 0.3s', fontFamily: 'Balerno, serif' }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.color = '#FFD700';
                                    e.currentTarget.style.transform = 'scale(1.2)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.color = '#CDAD72';
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            >
                                <i className="fas fa-arrow-down"></i>
                            </div>
                        </div>
                    </Card>
                    <Card style={{ border: 'none', boxShadow: 'none', fontFamily: 'Balerno, serif' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', fontFamily: 'Balerno, serif' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexDirection: 'column', fontFamily: 'Balerno, serif' }}>
                                <div style={{ textAlign: 'center', fontFamily: 'Balerno, serif', fontSize: '24px' }}>
                                    <strong>2. Plan Together</strong>
                                </div>
                                <div style={{ fontFamily: 'Balerno, serif', fontSize: '20px' }}>
                                    We create a plan that fits your budget and style. Whether it's a small gathering or a big celebration, we'll help you choose the right venue, food, and decorations.
                                </div>
                            </div>
                            <div 
                                style={{ color: '#CDAD72', fontSize: '24px', transition: 'color 0.3s, transform 0.3s', fontFamily: 'Balerno, serif' }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.color = '#FFD700';
                                    e.currentTarget.style.transform = 'scale(1.2)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.color = '#CDAD72';
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            >
                                <i className="fas fa-arrow-down"></i>
                            </div>
                        </div>
                    </Card>
                    <Card style={{ border: 'none', boxShadow: 'none', fontFamily: 'Balerno, serif' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', fontFamily: 'Balerno, serif' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexDirection: 'column', fontFamily: 'Balerno, serif' }}>
                                <div style={{ textAlign: 'center', fontFamily: 'Balerno, serif', fontSize: '24px' }}>
                                    <strong>3. Make It Happen</strong>
                                </div>
                                <div style={{ fontFamily: 'Balerno, serif', fontSize: '20px' }}>
                                    Zamari Events works with you to bring your ideas to life. We handle all the details and keep you involved in every decision to make sure everything is just right.
                                </div>
                            </div>
                            <div style={{ color: '#CDAD72', fontSize: '24px', fontFamily: 'Balerno, serif' }}>
                                <i className="fas fa-arrow-down"></i>
                            </div>
                        </div>
                    </Card>
                    <Card style={{ border: 'none', boxShadow: 'none', fontFamily: 'Balerno, serif' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', fontFamily: 'Balerno, serif' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flexDirection: 'column', fontFamily: 'Balerno, serif' }}>
                                <div style={{ textAlign: 'center', fontFamily: 'Balerno, serif', fontSize: '24px' }}>
                                    <strong>4. Deliver Your Perfect Day</strong>
                                </div>
                                <div style={{ fontFamily: 'Balerno, serif', fontSize: '20px' }}>
                                    On the big day, we take care of everything. Our team makes sure your event runs smoothly, so you can relax and enjoy every moment with your guests.
                                </div>
                            </div>
                        </div>
                    </Card>
                </section>

                <section style={{ padding: '48px 0', backgroundColor: '#fff', fontFamily: 'Balerno, serif', paddingTop: '48px' }}>
                    <Row justify="center">
                        <Col xs={22} sm={20} md={16} lg={14}>
                            <Title 
                                level={2} 
                                style={{ 
                                    textAlign: 'center', 
                                    marginBottom: '24px', 
                                    fontFamily: 'Balerno, serif',
                                    color: 'rgb(80, 80, 80)',
                                    backgroundColor: 'rgba(0, 0, 0, 0)'
                                }}
                            >
                            THE FOUNDER
                            </Title>
                            <Row align="middle" gutter={[48, 48]} style={{ marginBottom: '48px' }}>
                                <Col xs={24} md={12} order={{ xs: 2, md: 1 }}>
                                    <img
                                        src={homeImage}
                                        alt="Founder"
                                        className="default-image-height"
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Col>
                                <Col xs={24} md={12} order={{ xs: 1, md: 2 }}>
                                    <Paragraph style={{ fontSize: '20px', lineHeight: '1.8', fontFamily: 'Balerno, serif' }}>
                                        <strong>MEET OUR FOUNDER</strong><br/><br/>
                                        Our founder, Sharon Arisi, has been a visionary in the event planning industry since establishing building the company in 2023. She believes in the power of personalized service and attention to detail, ensuring that every event is a unique reflection of the client's vision. We continue to push the boundaries of what's possible in event planning.<br/><br/>
                                        Join us on this journey and experience the magic that we bring to every celebration.
                                    </Paragraph>
                                    <Button 
                                        type="text" 
                                        style={{ 
                                            marginTop: '16px', 
                                            border: '1px solid transparent', 
                                            color: 'gold', 
                                            backgroundColor: 'transparent', 
                                            transition: 'all 0.3s ease',
                                            fontFamily: 'Balerno, serif'
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.border = '1px solid gold'}
                                        onMouseLeave={(e) => e.currentTarget.style.border = '1px solid transparent'}
                                    >
                                        Learn More
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>

                <section style={{ padding: '80px 20px', maxWidth: '1200px', margin: '0 auto', textAlign: 'center', fontFamily: 'Balerno, serif' }}>
                    <Title level={2} style={{ marginBottom: '24px', color: 'var(--text-primary)', fontFamily: 'Balerno, serif' }}>
                        Talk to Us
                    </Title>
                    <Paragraph style={{ 
                        fontSize: '18px', 
                        maxWidth: '600px', 
                        margin: '0 auto 32px', 
                        color: 'var(--text-secondary)',
                        fontFamily: 'Balerno, serif'
                    }}>
                        We'd love to hear from you.
                    </Paragraph>
                    <Button 
                        type="text" 
                        href="/contact"
                        size="large"
                        style={{
                            height: '48px',
                            padding: '0 32px',
                            fontSize: '16px',
                            color: 'gold',
                            backgroundColor: 'transparent',
                            border: '1px solid gold',
                            transition: 'all 0.3s ease',
                            fontFamily: 'Balerno, serif'
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 215, 0, 0.1)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                    >
                        BOOK A CONSULTATION
                    </Button>
                </section>
            </div>
            <Footer />
        </>
    );
};

export default About;