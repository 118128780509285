import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, message, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import loginImage from '../../assets/d.jpg';
import './authstyles.css';

const { Title } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://api.unsplash.com/photos/random', {
          params: {
            count: 5,
            query: 'Black people Social Events (Birthday, Engagement, Anniversary)',
            client_id: process.env.REACT_APP_UNSPLASH_ACCESS_KEY
          },
          headers: {
            'Authorization': `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
          }
        });
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
        setImages([{
          urls: { regular: 'https://images.unsplash.com/photo-1523906834658-6e24ef2386f9' },
          alt_description: 'Default login background'
        }]);
      }
    };

    fetchImages();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL.replace('http://', 'https://');
      const response = await axios.post(`${apiUrl}/api/users/login`, values);
      message.success('Login successful!');
      navigate('/sellers');
    } catch (error) {
      message.error(error.response?.data?.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', position: 'relative' }}>
      <div style={{ 
        position: 'absolute',
        left: 0,
        top: 0,
        width: '75%',
        height: '100%',
        backgroundColor: '#f5f5f5',
        overflow: 'hidden'
      }}>
        {images.length > 0 ? (
          <div style={{ position: 'relative', height: '100%' }}>
            <img 
              src={images[0].urls.regular}
              alt={images[0].alt_description || "Login background"}
              style={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'cover',
                filter: 'brightness(0.4)'
              }} 
            />
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              width: '100%',
              padding: '0 20px'
            }}>
              <Title style={{ 
                color: 'white', 
                marginBottom: '16px',
                fontSize: '88px',
                fontFamily: 'Balerno, sans-serif'
              }}>
                Welcome Back
              </Title>
            </div>
          </div>
        ) : (
          <div style={{ 
            width: '100%', 
            height: '100%', 
            backgroundColor: '#f5f5f5' 
          }} />
        )}
      </div>

      <div style={{ 
        marginLeft: 'auto',
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px',
        minWidth: '340px'
      }}>
        <Card 
          title="Login" 
          bordered={false} 
          style={{ 
            width: 300,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          }}
        >
          <Form
            name="login"
            onFinish={onFinish}
            autoComplete="off"
            className="auth-form"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please enter a valid email!' }
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Log in
              </Button>
            </Form.Item>
            
            <div className="text-center">
              Don't have an account? <Link to="/signup">Sign up</Link>
              {' • '}
              <Link to="/">Home</Link>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;