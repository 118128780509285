import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Layout, 
  Row, 
  Col, 
  Typography, 
  Card, 
  Space, 
  Tag, 
  Spin, 
  Alert,
  Select,
  Input,
  Pagination,
  Button,
  Modal,
  Form,
  message
} from 'antd';
import { 
  CalendarOutlined, 
  ClockCircleOutlined, 
  UserOutlined,
  PlusOutlined 
} from '@ant-design/icons';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Meta } = Card;
const { Option } = Select;
const { Search } = Input;

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    category: '',
    status: 'published',
    search: '',
    page: 1,
    limit: 10
  });
  const [total, setTotal] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const categories = ['Technology', 'Lifestyle', 'Travel', 'Food', 'Other'];

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const queryParams = new URLSearchParams({
          ...filters,
          page: filters.page.toString(),
          limit: filters.limit.toString()
        });

        const response = await fetch(`/api/blogs?${queryParams}`);
        if (!response.ok) {
          throw new Error('Failed to fetch blogs');
        }
        
        const data = await response.json();
        setBlogs(data.blogs || []);
        setTotal(data.pagination?.total || 0);
      } catch (err) {
        setError(err.message);
        setBlogs([]);
        setTotal(0);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlogs();
  }, [filters]);

  const handleSearch = (value) => {
    setFilters(prev => ({ ...prev, search: value, page: 1 }));
  };

  const handleCategoryChange = (value) => {
    setFilters(prev => ({ ...prev, category: value, page: 1 }));
  };

  const handlePageChange = (page, pageSize) => {
    setFilters(prev => ({ ...prev, page, limit: pageSize }));
  };

  const handleCreateBlog = async (values) => {
    try {
      const response = await fetch('/api/blogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add your auth token here if required
          // 'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) throw new Error('Failed to create blog');
      
      const newBlog = await response.json();
      setBlogs(prev => [newBlog, ...prev]);
      message.success('Blog created successfully');
      setIsModalVisible(false);
      form.resetFields();
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Content style={{ padding: '48px 24px' }}>
        <Row justify="center">
          <Col xs={24} sm={20} md={16} lg={14}>
            <Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
              <Title level={1}>Blog Posts</Title>
              <Button 
                type="primary" 
                icon={<PlusOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Create Blog
              </Button>
            </Row>

            {/* Create Blog Modal */}
            <Modal
              title="Create New Blog"
              open={isModalVisible}
              onCancel={() => {
                setIsModalVisible(false);
                form.resetFields();
              }}
              footer={null}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={handleCreateBlog}
              >
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: 'Please enter a title' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="content"
                  label="Content"
                  rules={[{ required: true, message: 'Please enter content' }]}
                >
                  <Input.TextArea rows={6} />
                </Form.Item>

                <Form.Item
                  name="category"
                  label="Category"
                  rules={[{ required: true, message: 'Please select a category' }]}
                >
                  <Select>
                    {categories.map(category => (
                      <Option key={category} value={category}>{category}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="tags"
                  label="Tags"
                >
                  <Select mode="tags" placeholder="Add tags">
                    <Option value="technology">Technology</Option>
                    <Option value="lifestyle">Lifestyle</Option>
                    <Option value="travel">Travel</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="featuredImage"
                  label="Featured Image URL"
                >
                  <Input placeholder="https://example.com/image.jpg" />
                </Form.Item>

                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Create
                    </Button>
                    <Button onClick={() => setIsModalVisible(false)}>
                      Cancel
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Modal>

            {/* Filters */}
            <Row gutter={[16, 16]} style={{ marginBottom: '32px' }}>
              <Col xs={24} md={12}>
                <Search
                  placeholder="Search blogs..."
                  onSearch={handleSearch}
                  allowClear
                />
              </Col>
              <Col xs={24} md={12}>
                <Select
                  placeholder="Filter by category"
                  style={{ width: '100%' }}
                  onChange={handleCategoryChange}
                  allowClear
                >
                  {categories.map(category => (
                    <Option key={category} value={category}>{category}</Option>
                  ))}
                </Select>
              </Col>
            </Row>

            {isLoading ? (
              <Row justify="center" style={{ padding: '48px' }}>
                <Spin size="large" />
              </Row>
            ) : error ? (
              <Alert message="Error" description={error} type="error" showIcon />
            ) : blogs.length === 0 ? (
              <Alert
                message="No blogs found"
                description="Try adjusting your filters or create a new blog post."
                type="info"
                showIcon
              />
            ) : (
              <>
                <Row gutter={[24, 24]}>
                  {blogs.map((blog) => (
                    <Col xs={24} sm={24} md={12} key={blog._id || blog.slug}>
                      <Link to={`/blog/${blog.slug}`}>
                        <Card
                          hoverable
                          cover={
                            <img
                              alt={blog.featuredImage?.alt || blog.title}
                              src={blog.featuredImage?.url || 'https://via.placeholder.com/400x200'}
                              style={{ height: '200px', objectFit: 'cover' }}
                            />
                          }
                        >
                          <Space direction="vertical" size="small">
                            <Tag color="green">{blog.category}</Tag>
                            <Meta
                              title={blog.title}
                              description={
                                <Space direction="vertical" size={4}>
                                  <Space>
                                    <UserOutlined />
                                    <Text type="secondary">{blog.author?.name}</Text>
                                  </Space>
                                  <Space>
                                    <CalendarOutlined />
                                    <Text type="secondary">
                                      {new Date(blog.publishedAt).toLocaleDateString()}
                                    </Text>
                                  </Space>
                                  <Space>
                                    <ClockCircleOutlined />
                                    <Text type="secondary">{blog.readTime} min read</Text>
                                  </Space>
                                </Space>
                              }
                            />
                            <Text type="secondary" ellipsis={{ rows: 2 }}>
                              {blog.excerpt}
                            </Text>
                            {blog.tags?.length > 0 && (
                              <Space size={[0, 8]} wrap>
                                {blog.tags.map(tag => (
                                  <Tag key={tag} color="blue">{tag}</Tag>
                                ))}
                              </Space>
                            )}
                          </Space>
                        </Card>
                      </Link>
                    </Col>
                  ))}
                </Row>

                {total > 0 && (
                  <Row justify="center" style={{ marginTop: '32px' }}>
                    <Pagination
                      current={filters.page}
                      pageSize={filters.limit}
                      total={total}
                      onChange={handlePageChange}
                      showSizeChanger
                      showTotal={(total) => `Total ${total} items`}
                    />
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
      </Content>
      <Footer />
    </Layout>
  );
};

export default Blog; 